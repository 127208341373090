import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import '../App.min.css';

import Navigation from '../components/navigation/Navigation.js';
import Home from '../components/mainComponents/Home.js';
import About from '../components/mainComponents/About';
import Faq from '../components/mainComponents/FAQ';
import Legal from '../components/mainComponents/Legal';
import SupportDashboard from '../components/mainComponents/SupportComponents/SupportDashboard';

export default function App() {
  return (
    <>
      <Router>
        <Navigation/>
        <Switch>
          <Route exact path="/" component={Home}/>
          <Route path="/about" component={About}/>
          <Route path="/faqs" component={Faq}/>
          <Route path="/legal" component={Legal}/>
          <Route exact path="/support" component={SupportDashboard}/>
          <Route exact path="/support/:mode" component={SupportDashboard}/>
          <Route path="/support/:mode/:ticketId" component={SupportDashboard}/>
        </Switch>
      </Router>
      <div className="footer-container">
        <div className="container-middle-content footer">
          Copyright © 2020 Trippie | Powered by&nbsp; <a className="developer-link" rel="noreferrer" href="http://jamesdwhite.me/" target="_blank"> James White</a>
        </div>
      </div>
    </>
  );
}
