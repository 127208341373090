import React from 'react';

import TravellerStepOne from '../../../img/how-to-img/map-search.png';
import TravellerStepTwo from '../../../img/how-to-img/email-mail-sent.png';
import TravellerStepThree from '../../../img/how-to-img/receipt.png';
import TravellerStepFour from '../../../img/how-to-img/delivery-truck-white.png';
import TravellerStepFive from '../../../img/how-to-img/testimonial.png';

export default function HowToSender(){
  return(
    <div className="step-list white-borders">
      <div className="step-item icon-left">
        <div className="step-icon w30">
          <img
            className="step-icon-img"
            src={TravellerStepOne}
            alt="Step One"
          />
        </div>
        <div className="step-info w70">
          <h1 className="small-heading">Step 1: Find a suitable trip</h1>
          <p className="small-para">
            Browse all the listings on our App and select the ones that are
            most suitable for your item.
          </p>
        </div>
      </div>
      <div className="step-item icon-right">
        <div className="step-icon w30">
          <img
            className="step-icon-img"
            src={TravellerStepTwo}
            alt="Step One"
          />
        </div>
        <div className="step-info w70">
          <h1 className="small-heading">Step 2: Send a request</h1>
          <p className="small-para">
            Select the wanted trip and send them the delivery request you
            would like them to fulfil.
          </p>
        </div>
      </div>
      <div className="step-item icon-left">
        <div className="step-icon w30">
          <img
            className="step-icon-img"
            src={TravellerStepThree}
            alt="Step One"
          />
        </div>
        <div className="step-info w70">
          <h1 className="small-heading">Step 3: Accept an offer</h1>
          <p className="small-para">
            Talk with them via the Chat if needed or wait till they send you
            an offer. If you are happy with the offer, accept it.
          </p>
        </div>
      </div>
      <div className="step-item icon-right">
        <div className="step-icon w30">
          <img
            className="step-icon-img"
            src={TravellerStepFour}
            alt="Step One"
          />
        </div>
        <div className="step-info w70">
          <h1 className="small-heading">Step 4: Wait for Traveller to deliver item</h1>
          <p className="small-para">
            Now it is the travellers turn to do their part. Watch them update
            the status of the item while they go through the process of
            delivering it.
          </p>
        </div>
      </div>
      <div className="step-item icon-left">
        <div className="step-icon w30">
          <img
            className="step-icon-img"
            src={TravellerStepFive}
            alt="Step One"
          />
        </div>
        <div className="step-info w70">
          <h1 className="small-heading">Step 5: Rate the experience</h1>
          <p className="small-para">
            Once your item has been dropped off ensure to rate your
            experience with the Traveller this finalises the process and
            ensures other users know who to pick when selecting a traveller
            to deliver their items.
          </p>
        </div>
      </div>
    </div>
  );
}
