import React, {useState} from 'react';
import Helmet from 'react-helmet'
import {auth} from '../../Firebase'
import {Spinner, Message, displaySpinner, displayMessage} from '../ModalComponents/Spinner.js'


function signInWithEmailAndPasswordHandler(email, password){
  displaySpinner(true);
  auth.signInWithEmailAndPassword(email, password).then((userCred) => {
    displaySpinner(false);

    if (userCred && !userCred.user.emailVerified) {
      auth.signOut();
      throw new Error("The email associated with this account has not been verified!");
    }

  }).catch((error) => {
    displayMessage(true, false, error.message);
    displaySpinner(false);
  });
}

export default function Login(){

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  return(
    <div className="login-container">
      <Helmet>
        <title>Trippie Air - Login</title>
        <meta name="description" content="Trippie Air" />
      </Helmet>
      <Spinner/>
      <Message/>
      <div className="login-box">
        <div className="login-form">
          <div className="login-message">
            Sign in to your existing Trippie Air account access support
          </div>
          <input
            required={true}
            type="email"
            placeholder="Email address"
            value={email}
            onChange={e => setEmail(e.target.value)}
            />
          <input
            required={true}
            type="password"
            placeholder="Password"
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
          <div className="login-button">
            <button className="standard-button"onClick={() => signInWithEmailAndPasswordHandler(email, password)}>Login</button>
          </div>
        </div>
      </div>
    </div>
  );
}
