import React, {useState, useEffect, useRef} from 'react';
import {Helmet} from 'react-helmet';
import {firestore, Timestamp, FieldValue} from '../../Firebase';
import {Message, displayMessage} from '../ModalComponents/Spinner';
import {Link} from 'react-router-dom';

export default function Ticket(props){

  const endDivRef = useRef(null);

  const [ticket, setTicket] = useState();
  const [ticketLoaded, setTicketLoaded] = useState(false);
  const [messages, setMessages] = useState();
  const [reply, setReply] = useState("");

  const disableReplyBtn = (disable) => {
    const replyBtn = document.getElementById("replyButton");
    if (disable) {
      replyBtn.classList.add("disabled");
    } else {
      replyBtn.classList.remove("disabled");
    }
  }

  const scrollToBottom = () => {
    if (endDivRef) {
      endDivRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start'});
    }
  }

  const getTicketNo = () => {
    if (!ticketLoaded) {
      return "Loading...";
    } else {
      if (ticket) {
        return `Ticket #${ticket.ticketNo}`;
      } else {
        return "Ticket does not exist";
      }
    }
  }

  const ticketRef = firestore.collection('support_tickets')
    .doc(props.userId)
    .collection('tickets')
    .doc(props.ticketId);

  useEffect(() => {

    ticketRef.get()
      .then((docSnap) => {
        setTicketLoaded(true);
        if(docSnap.exists) setTicket(docSnap.data());
      });

    const listener = ticketRef.collection('messages')
      .orderBy('timestamp')
      .onSnapshot((querySnap) => {
        const messageList = [];
        querySnap.forEach((docSnap) => {
          messageList.push(docSnap.data());
        })
        setMessages(messageList);
      });

    return function cleanUp() {
      listener();
    }
  }, []);

  useEffect(() => {

    scrollToBottom();

  }, [messages]);

  const sendReply = () => {

    if (!ticket || !ticket.open || reply === "") return;

    disableReplyBtn(true);

    const message = {
      messageBody: reply,
      timestamp: FieldValue.serverTimestamp(),
      userId: props.userId
    }

    ticketRef.collection('messages')
      .doc()
      .set(message)
      .then(() => {
        setReply('');
      })
      .catch((error) => {
        displayMessage(true, false, 'You can no longer send a message in this ticket. Please create a new ticket!');
        if (reply !== "") {
          disableReplyBtn(false);
        }
      });
  }

  const messageList = [];
  if (messages) {

    messages.map((data, key) => {
      messageList.push((
        <React.Fragment key={key}>
          <div className={`ticket-message ${data.admin ? '' : 'from-admin'}`}>
            <div className="message">
              {data.messageBody}
              <div className="from-who">{data.admin ? 'Admin' : 'Me'}</div>
            </div>
            {data.timestamp ? data.timestamp.toDate().toLocaleString('en-NZ') : ""}
          </div>
        </React.Fragment>
      ));

    })

  }

  return(
    <div className="home">
      <Helmet>
        <title>Trippie Air - Support Ticket #{ticket ? `${ticket.ticketNo}` : ''}</title>
        <meta name="description" content="Trippie Air" />
      </Helmet>
      <Message/>
      <div className="home-hero bg-gradient">
        <div className="container-middle-content">
          <div className="tickets-messages-header-text">
            <h1 className="medium-heading">Support Ticket</h1>
            <p className="medium-para">
                {getTicketNo()}
              <br/>
                {ticket ? ticket.reason : ''}
            </p>
            <Link
              to="/support/"
              className="standard-button invert-btn-color">
              My Tickets
            </Link>
          </div>
        </div>
      </div>
      <div className="container-middle-content ">
        <div id="messageContainer" className="ticket-messages-container">
          {messageList}
          <div ref={endDivRef}></div>
        </div>
        {ticket && ticket.open ?

          <div className="reply-container">
            <input
              type='text'
              placeholder="Write a message"
              value={reply}
              onChange={e => {
                const input = e.target.value;
                disableReplyBtn(input === "");
                setReply(input);
              }}
              onKeyDown={e => {
                if (e.key === "Enter" && reply !== "") {
                  sendReply();
                }
              }}
            />
            <button id="replyButton" className="reply-button disabled" onClick={() => {
              if (reply !== "") {
                sendReply();
              }
            }}>
              <i className="far fa-paper-plane"></i>
            </button>
          </div>
          :
          <></>

        }

      </div>
    </div>
  )
}
