import React, { useState } from 'react';
import { Helmet } from 'react-helmet';

import FaqList from './FaqComponents/FaqList';

export default function About(){

  const [faqType, setFaqType] = useState("general");

  return(
    <div className="home">
      <Helmet>
        <title>Trippie Air - FAQ's</title>
        <meta name="description" content="Trippie Air" />
      </Helmet>
      <div className="home-hero bg-gradient">
        <div className="container-middle-content">
          <div className="about-header-text">
            <h1 className="medium-heading">Frequently Asked Questions</h1>
            <p className="medium-para">
              Got a question? We have a list of frequently asked question so you can use Trippie Air like a pro.
            </p>
          </div>
        </div>
      </div>
      <div className="bg-gradient">
        <div className="container-middle-content">
          <div className="switch-buttons-container">
            <button className={(faqType === "general") ? "switch-buttons active fw-600" : "switch-buttons fw-600"} onClick={() => setFaqType("general")}>
              General
            </button>
            <button className={(faqType === "sender") ? "switch-buttons active fw-600" : "switch-buttons fw-600"} onClick={() => setFaqType("sender")}>
              Sender
            </button>
            <button className={(faqType === "traveller") ? "switch-buttons active fw-600" : "switch-buttons fw-600"} onClick={() => setFaqType("traveller")}>
              Traveler
            </button>
          </div>
        </div>
      </div>
      <div className="container-middle-content faq-container">
        <FaqList faqType={faqType} />
      </div>
    </div>
  );
}
