import React, {useState, useEffect} from 'react';
import Helmet from 'react-helmet';
import {firestore} from '../../Firebase'
import {Link} from 'react-router-dom';

export default function TicketList(props){

  const [ticketType, setTicketType] = useState(true);
  const [ticketData, setTicketData] = useState([]);

  const user = props.user;

  useEffect(() => {
    
    const snapListener = firestore.collection('support_tickets')
      .doc(user.uid)
      .collection('tickets')
      .where('open', '==', ticketType)
      .orderBy("lastMessage", "desc")
      .onSnapshot((querySnap) => {

        let ticketList = [];
        querySnap.forEach((docSnap) => {
          ticketList.push(docSnap);
        })
        setTicketData(ticketList);

      })

    return () => {
      if (snapListener) {
        snapListener();
      }
    }

  }, [ticketType, user.uid]);

  return(
    <div className="home">
      <Helmet>
        <title>Trippie Air - Support</title>
        <meta name="description" content="Trippie Air" />
      </Helmet>
      <div className="home-hero bg-gradient">
        <div className="container-middle-content">
          <div className="tickets-header-text">
            <h1 className="medium-heading">Support Tickets List</h1>
            <Link
              to="/support/create-ticket"
              className="standard-button invert-btn-color">
              New Ticket
            </Link>
          </div>
        </div>
      </div>
      <div className="bg-gradient">
        <div className="container-middle-content">
          <div className="switch-buttons-container">
            <button className={(ticketType) ? "switch-buttons terms-buttons active fw-600" : "switch-buttons terms-buttons fw-600"} onClick={() => setTicketType(true)}>
              Open Tickets
            </button>
            <button className={(!ticketType) ? "switch-buttons terms-buttons active fw-600" : "switch-buttons terms-buttons fw-600"} onClick={() => setTicketType(false)}>
              Closed Tickets
            </button>
          </div>
        </div>
      </div>
      <div className="container-middle-content">
        <div className="support-ticket-list">
          <h1 className="small-heading">List of all your {ticketType ? "open" : "closed"} tickets</h1>
          <div className="ticket-box-title fw-600">
            <div className="ticket-id w20">
              Ticket Number
            </div>
            <div className="ticket-reason w40">
              Reason
            </div>
            <div className="ticket-date w20">
              Date of Submission
            </div>
            <div className="ticket-date w20">
              Last Message
            </div>
          </div>
          {
            ticketData.length > 0
              ?
            ticketData.map((doc, key) => {
              return(
                <React.Fragment key={key}>
                  <Link
                    className="ticket-link"
                    to={`/support/ticket/${doc.id}`}>
                    <div className="ticket-box">
                      <div className="ticket-id w20">
                        {doc.data().ticketNo}
                      </div>
                      <div className="ticket-reason w40">
                        {doc.data().reason}
                      </div>
                      <div className="ticket-date w20">
                        {doc.data().creationDate.toDate().toLocaleString("en-NZ")}
                      </div>
                      <div className="ticket-date w20">
                        {doc.data().lastMessage.toDate().toLocaleString("en-NZ")}
                      </div>
                    </div>
                  </Link>
                </React.Fragment>
              )
            })
              :
            <div className="ticket-link">
              <div className="ticket-box">
                <div className="ticket-reason w100">
                  You do not have any {ticketType? "open" : "closed"} tickets
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  )
}
