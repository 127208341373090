import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCzjJuJpIQr8B8Vk59U-8B_gRbhCpEhIjA",
  authDomain: "trippie-air-live.firebaseapp.com",
  databaseURL: "https://trippie-air-live.firebaseio.com",
  projectId: "trippie-air-live",
  storageBucket: "trippie-air-live.appspot.com",
  messagingSenderId: "533836727206",
  appId: "1:533836727206:web:f7fc81c6c0eaedb2af0567",
  measurementId: "G-E52YBSDSLC"
};

firebase.initializeApp(firebaseConfig);
export const firestore = firebase.firestore();
export const auth = firebase.auth();
export const functions = firebase.app().functions("asia-northeast1");
export const Timestamp = firebase.firestore.Timestamp;
export const FieldValue = firebase.firestore.FieldValue;
