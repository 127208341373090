import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {firestore} from '../Firebase';

export default function Legal(){

  const [page, setPage] = useState("terms");
  const [linkData, setLinkData] = useState();

  useEffect(() => {
    const fetchData = async() => {
      try {
        firestore.collection('legal')
          .doc('links')
          .get()
          .then((docSnap) => {
            if(docSnap.exists) setLinkData(docSnap.data());
          })
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  const link = linkData ? linkData[page] : null;

  return(
    <div className="home">
      <Helmet>
        <title>Trippie Air - Legal</title>
        <meta name="description" content="Trippie Air" />
      </Helmet>
      <div className="home-hero bg-gradient">
        <div className="container-middle-content">
          <div className="about-header-text">
            <h1 className="medium-heading">Frequently Asked Questions</h1>
            <p className="medium-para">
              Got a question? We have a list of frequently asked question so you can use Trippie Air like a pro.
            </p>
          </div>
        </div>
      </div>
      <div className="bg-gradient">
        <div className="container-middle-content">
          <div className="switch-buttons-container">
            <button className={(page === "terms") ? "switch-buttons terms-buttons active fw-600" : "switch-buttons terms-buttons fw-600"} onClick={() => setPage("terms")}>
              Terms and Conditions
            </button>
            <button className={(page === "privacy") ? "switch-buttons terms-buttons active fw-600" : "switch-buttons terms-buttons fw-600"} onClick={() => setPage("privacy")}>
              Privacy Policy
            </button>
          </div>
        </div>
      </div>
      <div className="container-middle-content faq-container">
        {
          link
            ?
          <iframe className="doc-view" src={link}></iframe>
            :
          <></>
        }
      </div>
    </div>
  );
}
