import React from 'react';
import { Helmet } from 'react-helmet';

import GooglePlayDownload from '../../img/google-play-button.png'
import AppImage from '../../img/trippie-air-phone-display.png'

export default function Home(){
  return(
    <div className="home">
      <Helmet>
        <title>Trippie Air - Home</title>
        <meta name="description" content="Trippie Air" />
      </Helmet>
      <div className="home-hero bg-gradient">
        <div className="container-middle-content">
          <div className="home-header-text">
            <h1 className="medium-heading">Welcome to Trippie Air where we connect Travellers to people in the need of delivering an item within New Zealand!</h1>
            <p className="medium-para">
              Have you been looking for an alternative way of sending packages to friends and family across the country?
               Are you looking for a cheaper option? Maybe a more personal option, or even a more environmentally friendly option?
               Here at Trippie Air that is exactly what we aim to provide. Maybe you are always on the go traveling from one place
               to another and always have extra baggage space. Well, here at Trippie Air we help you use that extra baggage space
               to make some money while you relax on the plane.
            </p>
          </div>
        </div>
      </div>
      <div className="container-middle-content">
        <div className="information-box">
          <div className="information-row">
            <div className="information-cell">
              <i className="fas fa-plane"></i>
              <div className="info-text">
                <h1 className="small-heading text-align-center">
                  Distance Delivery
                </h1>
                <p className="small-para">
                  TrippieAir is an exciting new delivery service app
                  that connects customers who have items they need delivered
                  with Travelers who have the means and opportunity to transport
                  your package.
                </p>
              </div>
            </div>
            <div className="information-cell">
              <i className="fas fa-users"></i>
              <div className="info-text">
                <h1 className="small-heading">
                  Community based service
                </h1>
                <p className="small-para">
                  TrippieAir aims to be community based rather than
                  employing a fleet of delivery drivers.
                </p>
              </div>
            </div>
            <div className="information-cell">
              <i className="fas fa-comments-dollar"></i>
              <div className="info-text">
                <h1 className="small-heading">
                  Competitive Pricing
                </h1>
                <p className="small-para">
                  TrippieAir uses a peer-to-peer on demand business model. This
                  means we can keep operating costs very low because we do not
                  maintain a fleet of vehicles or employ full time service providers
                  and we are able to pass these savings on to consumers.
                </p>
              </div>
            </div>
          </div>
          <div className="information-row">
            <div className="information-cell">
              <i className="fas fa-mobile-alt"></i>
              <div className="info-text">
                <h1 className="small-heading">
                  Easy to use app
                </h1>
                <p className="small-para">
                  TrippieAir takes a modern approach to the user experience,
                  aiming to make all aspects of the app easy to use for the
                  users benefit.
                </p>
              </div>
            </div>
            <div className="information-cell">
              <i className="fas fa-leaf"></i>
              <div className="info-text">
                <h1 className="small-heading">
                  Environmentally friendly
                </h1>
                <p className="small-para">
                  TrippieAir to assist in the ever growing problem of environmental
                  damage. Using an on-the-way delivery service means your good will
                  pass from the customer to the travelers and then to their intended recipient
                  with less CO<sub>2</sub> emission and packaging waste.
                </p>
              </div>
            </div>
            <div className="information-cell">
              <i className="fas fa-piggy-bank"></i>
              <div className="info-text">
                <h1 className="small-heading">
                  Earn as a Traveler
                </h1>
                <p className="small-para">
                  If you already have plans to travel domestically, why not
                  earn some extra money by taking a package with you. TrippieAir connects
                  users needing items taken from City to City with traveler
                  who are planning to Travel alright.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="app-section bg-gradient">
        <div className="container-middle-content">
          <div className="row">
            <div className="med-60">
              <div className="text-housing-download">
                <h1 className="large-heading">Join TrippieAir</h1>
                <p className="large-para">
                  The TrippieAir app connects customers with travelers, who can
                  take good across the country. Download TrippieAir today.
                </p>
                <div className="">
                  <a href="#">
                    <img
                      className="download-image"
                      src={GooglePlayDownload}
                      alt=""
                    />
                  </a>
                  </div>
                </div>
              </div>
              <div className="med-40">
                <img
                  className="app-image"
                  src={AppImage}
                  alt=""
                />
              </div>
            </div>
        </div>
      </div>
    </div>
  );
}
