import React from 'react';

import GreenCheck from '../../../img/greencheck.png';
import RedCross from '../../../img/redcross.png'

export function displaySpinner(show){
  var spinner = document.getElementById("loadSpinner");
  show ? spinner.style.display = "block" : spinner.style.display = "none";
  show ? document.body.style.overflow = 'hidden' : document.body.style.overflow = "unset";
}

export function displayMessage(show, status, message){
  var messageBox = document.getElementById("messageBox");
  var messageBoxText = document.getElementById("messageBoxText");
  var imgBox = document.getElementById('imgBox');
  imgBox.src = status ? GreenCheck : RedCross;
  show ? messageBox.style.display = "block" : messageBox.style.display = "none";
  show ? document.body.style.overflow = 'hidden' : document.body.style.overflow = "unset";
  messageBoxText.innerHTML = message;
}

export function Spinner(){
  return(
    <div id="loadSpinner" className="spinner-background">
      <div className="spinner-container">
        <div className="spinner loading">
        </div>
      </div>
    </div>
  );
}

export function Message(){
  return(
    <div id="messageBox" className="message-box">
      <div className="message-container">
        <div className="message-body">
          <div className="message-icon">
            <img
              id='imgBox'
              alt="ERROR"
            />
          </div>
          <div id="messageBoxText" className="message-info">

          </div>
        </div>
        <div className="message-button-body">
          <button onClick={() => displayMessage(false, false, '')}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
}
