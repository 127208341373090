import React, {useEffect, useState} from 'react';
import { NavLink } from "react-router-dom";
import {auth} from '../Firebase'

import TrippieLogo from "../../img/trippie_logo.png";
import TrippiePlane from "../../img/trippie-air-plane.svg";

function handleNavigation(){
    let menuBtn = document.getElementById('menuHamburger');
    let mobileNav = document.getElementById("nav-list");
    menuBtn.classList.contains('open') ? menuBtn.classList.remove('open') : menuBtn.classList.add('open');
    menuBtn.classList.contains('open') ? mobileNav.classList.add('open') : mobileNav.classList.remove('open');
}

export default function Navigation(){

  const [user, setUser] = useState();

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      setUser(user);
    });
  });

  return(
    <nav className="nav-bar">
        <div className="nav-bar-container container-middle-content">
          <NavLink
            className="nav-bar-personal-name fs-20"
            to="/">
            <div className="trippieair-logo">
              <img
                className="trippie-logo"
                src={TrippieLogo}
                alt=""
              />
              <img
               className="flying-plane"
               src={TrippiePlane}
               alt=""
              />
            </div>
          </NavLink>

          <div className="menu-btn-container">
            <div id="menuHamburger" className="menu-btn" onClick={handleNavigation}>
              <div className="menu-btn-burger">
              </div>
            </div>
          </div>

          <div id="nav-list" className="responsive-nav-bar">
            <ul className="nav-bar-list">
              <li className="nav-bar-list-item">
                <NavLink
                  onClick={handleNavigation}
                  exact to="/"
                  className="nav-bar-link">
                  Home
                </NavLink>
              </li>
              <li className="nav-bar-list-item">
                <NavLink
                  onClick={handleNavigation}
                  to="/about"
                  className="nav-bar-link">
                  About
                </NavLink>
              </li>
              <li className="nav-bar-list-item">
                <NavLink
                  onClick={handleNavigation}
                  to="/faqs"
                  className="nav-bar-link">
                  FAQ's
                </NavLink>
              </li>
              <li className="nav-bar-list-item">
                <NavLink
                  onClick={handleNavigation}
                  to="/legal"
                  className="nav-bar-link">
                  Terms and Conditions
                </NavLink>
              </li>
              <li className="nav-bar-list-item">
                <NavLink
                  onClick={handleNavigation}
                  exact to="/support"
                  className="nav-bar-link">
                  Support
                </NavLink>
              </li>

              {user ?
                <li className="nav-bar-list-item">
                  <div className="logout-btn" onClick={() => auth.signOut()}>
                    Sign Out
                  </div>
                </li>
                :
                <></>
              }

            </ul>
          </div>
        </div>
      </nav>
  );
}
