import React from 'react';
import { Helmet } from 'react-helmet';

import HowToTraveller from './HowToComponents/HowToTraveller';
import HowToSender from './HowToComponents/HowToSender';

export default function About(){
  return(
    <div className="home">
      <Helmet>
        <title>Trippie Air - About Us</title>
        <meta name="description" content="Trippie Air" />
      </Helmet>
      <div className="home-hero bg-gradient">
        <div className="container-middle-content">
          <div className="about-header-text">
            <h1 className="medium-heading">About us</h1>
            <p className="medium-para">
              Trippie Air was created to provide New Zealand with an alternative way of sending packages within the country.
              We believe that the little things make a difference. We live in a world that allows so many of us to travel
              regularly, be it for work or leisure. Why not use this to help our world and make a difference.
              Carbon dioxide emissions from all the traffic due to delivery is growing more and more every year.
              We here at Trippie Air hope our Mobile App will help decrease this by using people that are already
              planning a trip to delivery items for people within New Zealand. So join our mission and help New Zealand
              support each other and this world we live in.
            </p>
          </div>
        </div>
      </div>
      <div className="container-middle-content">
        <div className="how-to-container">
          <h1 className="small-heading">Steps to being a Traveler</h1>
          <HowToTraveller/>
        </div>
      </div>
      <div className="app-section bg-gradient">
        <div className="container-middle-content how-to-container">
          <h1 className="small-heading">Steps to sending a item</h1>
          <HowToSender/>
        </div>
      </div>
    </div>
  );
}
